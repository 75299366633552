@import "./shared/page";

#app{
  filter: none;
}
.invalid-input-feedback {
  color: rgb(206, 15, 15);
  text-align: left;
  padding-top: 1px;
  font-size: 13px;
}
.error-msg {
  color: rgb(206, 15, 15);
  text-align: left;
  padding-top: 10px;
  font-size: 13px;
}
.success-msg {
  color: rgb(59, 255, 20);
  text-align: left;
  padding-top: 10px;
  font-size: 13px;
}
.confirmation-alert {
  font-family: $FONT-FAMILY;
  background: rgba($color: #000000, $alpha: 0.5) !important;
  .confirm-alert-body {
    padding: 30px;
    text-align: left;
    border-radius: 10px;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  }
}
.select-container{
  position: relative;
  .spinner-select{
    position: absolute;
    left: calc(100% - 100px);
    top: 50%;
    opacity: 0.5;
  }
}