/** COLORES */
$BLACK: #000;
$WHITE: #fff;
$MAIN1: #2330D7;
$MAIN2: #7BDE8E;
$MAIN3: #e3e3e3;

/** PAGINA */
$FONT-FAMILY: "Roboto", "sans-serif";
$FONT-SIZE: 16px;

/** SIDEBAR */
$WIDTH-SIDEBAR: 180px;
$WIDTH-SIDEBAR-MINI: 50px;

/** TOPBAR */
$HEIGHT-TOPBAR: 60px;

/** PAGE TITLE */
$HEIGHT-PAGE-TITLE: 40px;

/* TAB */
$HEIGHT-TAB: 42px;

/*|---------------| BREAKPOINTS|---------------*/

// Usage example:  @media #{$BREAKPOINT-DESK}

$BREAKPOINT-DESK: "only screen and (min-width: 1280px)";
$BREAKPOINT-TABLET-LAND: "only screen and (min-width: 975px)";
$BREAKPOINT-TABLET: "only screen and (min-width: 600px)";
$BREAKPOINT-MOBILE-LAND: "only screen and (min-width: 480px)";